import * as React from "react"
import PrivateRoute from "../../../components/privateRoute"
import SpreadDetail from "../../../components/Drive/SpreadSheet/detail"
import ReduxWrapper from "../../../redux/reduxWrapper"

const SpreadSheetDetails = props => {
  return <PrivateRoute component={SpreadDetail} location={props.location} selectedDocId={props.id} />
}

const WrappedPage = props => <ReduxWrapper element={<SpreadSheetDetails {...props} />} />
export default WrappedPage
